/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, YouTubeAsBackground, ColumnWrap, ColumnWrapper, Title, Button, Image, Subtitle, Text, Slideshow, YouTube, Voucher } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Voucher"} description={"Voucher"}>
        <SiteHeader />

        <Column className="--center" name={"68f01ob4qss"} style={{"paddingTop":213,"paddingBottom":8}}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"r8qVRr8cFFQ"} transformations={{"hue":0,"blur":0,"sepia":0,"overlay":"#000000","contract":110,"contrast":100,"brightness":85,"saturation":100,"overlayAlpha":75}} />
          
          <ColumnWrap className="column__flex js-anim  --anim6 --center el--1" anim={"6"} style={{"maxWidth":912}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"marginBottom":49}}>
              
              <Title className="title-box title-box--center fs--72 w--400 ls--002 lh--12" style={{"marginBottom":28}} content={"<span style=\"color: var(--color-custom-1--95);\">CHCEŠ SI SPLNIT SEN? </span><span style=\"font-weight: bold;\"><span style=\"color: var(--color-custom-1);\">ZÁŽIJ <span style=\"text-decoration-line: underline;\">JÍZDU V TESLE Y</span></span><span style=\"color: var(--color-custom-1--95);\">&nbsp;</span></span><br>"}>
              </Title>

              <Button className="btn-box btn-box--sbtn2 btn-box--pbtn3 btn-box--cColor2 btn-box--center" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":0}} content={"<span style=\"color: rgb(242, 247, 254); text-decoration-line: underline;\">Zobrazit <span style=\"\">nabídku</span></span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim6 --center el--1" anim={"6"} style={{"maxWidth":912}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --left" anim={"6"} style={{"marginBottom":11}}>
              
              <Image alt={"Přejet níže"} src={"https://cdn.swbpg.com/o/27050/09b675a814844d70b9d725fa2c6e6db8.svg"} use={"page"} href={"/voucher/page#voucher"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":20}} srcSet={""} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-12c1ycd --center" name={"preheader"} style={{"paddingTop":25,"paddingBottom":29}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/27050/e93e0141ede741d589428e793f670700_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --left" anim={"2"}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-1--95);\"><span style=\"text-decoration:line-through;;\">TESLA 3</span>&nbsp;od 2023 ŽAŽITKOVÉ JÍZDY V </span><span style=\"font-weight: bold;\"><span style=\"color: var(--color-custom-1--95);\">TESLE Y&nbsp;</span><br></span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--center" style={{"marginTop":6}} content={"<span style=\"color: var(--color-custom-2);\"><span style=\"font-size: 70%; vertical-align:baseline;\">od</span> <span style=\"font-weight: bold;\">1400 Kč</span><br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3 pl--10 pr--10 flex--stretch" style={{"maxWidth":""}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --left pb--16 pl--20 pr--16 pt--20" anim={"2"} style={{"backgroundColor":"rgba(244,244,244,1)"}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5295b4c8c0d74a51b522ffb0650bcc1f.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(0,96,237,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" style={{"marginTop":6}} content={"<span style=\"color: var(--color-supplementary);\">Chci řídit Teslu Y&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 lh--14" style={{"maxWidth":312,"marginTop":9}} content={"<span style=\"color: var(--color-blend--15);\">Perfektní volba pro zvídavé lidi, kteří chtějí zažít adrenalin a moderní technologie na vlastní kůži!</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--16" use={"page"} href={"/voucher/page#proc"} style={{"marginTop":20}} content={"Chci řídit Teslu&nbsp;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --left pb--16 pl--20 pr--16 pt--20" anim={"2"} style={{"backgroundColor":"rgba(244,244,244,1)"}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/0c409514248843fc87e83d8fb5c6039a.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(0,96,237,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" style={{"marginTop":6}} content={"<span style=\"color: var(--color-supplementary);\">Nechám se svést</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--16 lh--14" style={{"maxWidth":312,"marginTop":9}} content={"<span style=\"caret-color: rgb(55, 64, 72); color: rgb(55, 64, 72);\">Sveze Vás náš instruktor, ukáže co vše auto umí a zasvětí Vás do tajů Tesly<br></span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--16" use={"page"} href={"/voucher/page#proc"} style={{"marginTop":20}} content={"Chci se svést v Tesle"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --shadow2 --left pb--16 pl--20 pr--16 pt--20" anim={"2"} style={{"backgroundColor":"rgba(244,244,244,1)"}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/191af91631714358843c81426bf7f35f.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(0,96,237,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" style={{"marginTop":6}} content={"<span style=\"color: var(--color-supplementary);\">Odpovědi na otázky</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--16 lh--14" style={{"maxWidth":312,"marginTop":9}} content={"<span style=\"color: var(--color-blend--15);\">Nejčastěji se ptáte...klikněte na FAQ<br><br><br></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--16" use={"page"} href={"/voucher/page#faq"} style={{"marginTop":20}} content={"Přejít na FAQ"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" name={"proc"} style={{"paddingTop":74}}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1140,"marginBottom":62}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--900 lh--16" content={"<span style=\"color: rgb(159, 187, 208);\">Vyber si ten správný zážitek</span>"}>
              </Text>

              <Title className="title-box w--600" style={{"marginTop":4}} content={"Proč si půjčit Teslu a od ledna 2023 dokonce Teslu Y?!"}>
              </Title>

              <Text className="text-box lh--16" style={{"maxWidth":678}} content={"Zažijte automobilovou budoucnost už dnes, poznejte všechny možnosti Tesly od propracovaného ovládání, přes inovativní funkce a výbavu a konče pocitem řízení auta o výkonu přes 500koní se zrychlením 0-100km/h za 5sekund!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 pl--10 pr--10 flex--stretch" style={{"maxWidth":1140,"marginBottom":0,"backgroundColor":"var(--color-custom-2)"}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --left pb--16 pl--20 pr--16 pt--20" anim={"2"} style={{"backgroundColor":"var(--color-custom-1--95)"}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5295b4c8c0d74a51b522ffb0650bcc1f.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(27,37,46,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" style={{"maxWidth":453,"marginTop":6}} content={"<span style=\"color: var(--color-supplementary);\">Pro ty, kteří chtějí mít vše ve vlastních rukou a plně pod kontrolou</span><br>"}>
              </Subtitle>

              <Text className="text-box w--500 lh--16" content={"<span style=\"color: var(--color-blend--15);\">Možnost vyzkoušet si Teslu Y na vlastní kůži, budete řidičem a garantujeme, že Vás jízda nenechá chladnými!</span>"}>
              </Text>

              <Text className="text-box fs--16 lh--16" style={{"maxWidth":"","marginTop":33}} content={"<span style=\"color: var(--color-blend--15);\">O unikátních vlastnostech Tesly a parametrech se toho dočtete dost, ale ZAŽÍT NA VLASTNÍ kůži Teslu je něco jiného, v přímém přenosu zažijete úžasné zrychlení, technologie budoucnosti a spoustu dalšího. Stačí si vybrat jeden z nabízených zážitků. Na všem ostatním se domluvíme.</span><br>"}>
              </Text>

              <Image className="--shape4" alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/d6b70ca91f894395a91f31b2dd8c35b4_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/27050/d6b70ca91f894395a91f31b2dd8c35b4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/d6b70ca91f894395a91f31b2dd8c35b4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/d6b70ca91f894395a91f31b2dd8c35b4_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/d6b70ca91f894395a91f31b2dd8c35b4_s=1400x_.jpeg 1400w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 btn-box--center fs--18" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":20}} content={"Koupit poukaz"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --left pb--16 pl--20 pr--16 pt--20" anim={"2"} style={{"backgroundColor":"rgba(244,244,244,1)"}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/0c409514248843fc87e83d8fb5c6039a.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":60}} srcSet={""} svgColor={"rgba(55,64,72,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" style={{"maxWidth":334,"marginTop":6}} content={"<span style=\"color: var(--color-blend--05);\">A co když se chci jenom tak svézt?&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box w--500 lh--16" content={"<span style=\"color: var(--color-blend--15);\">To je to nejmenší. Náš kovaný Teslař Vám zprostředkuje krásný zážitek a zasvětí Vás do tajů naší Tesly. Jen tak na to nezapomenete.</span><br>"}>
              </Text>

              <Text className="text-box fs--16 lh--16" style={{"maxWidth":"","marginTop":33}} content={"<span style=\"color: var(--color-blend--15);\">Pokud nemůžete, nebo nechcete řídit není to problém. Svezeme Vás kamkoliv si budete přát. Jet můžete klidně i s přáteli a po celou dobu jízdy bude instruktor připraven odpovídat na Vaše otázky jak nejlépe bude umět.<br></span><br>"}>
              </Text>

              <Image className="--shape4" alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/b03e90c8cf5e4d84b91e72f1d8004995_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/b03e90c8cf5e4d84b91e72f1d8004995_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/b03e90c8cf5e4d84b91e72f1d8004995_s=660x_.jpeg 660w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 btn-box--center fs--18" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":20}} content={"Koupit poukaz"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --left pb--0 pl--0 pr--0 pt--0" anim={"2"} style={{"backgroundColor":"var(--black)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"9zs6pc6dyvh"} effect={"fade"} autoplay={true} infinite={true} transitionDuration={3}>
          
          <Column className="pb--20 pt--20">
            
            <ColumnWrap className="column__flex --center el--3" columns={"3"}>
              
              <ColumnWrapper className="--shadow2 --center pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Image alt={"Recenze"} src={"https://cdn.swbpg.com/t/27050/179086e1f3054c90ab47759223423bd2_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":160}} srcSet={"https://cdn.swbpg.com/t/27050/179086e1f3054c90ab47759223423bd2_s=350x_.png 350w"}>
                </Image>

                <Title className="title-box fs--18 w--600" style={{"marginTop":13}} content={"Hodnocení 5,0"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319,"marginTop":4}} content={"<span style=\"color: var(--color-blend--15);\">Recenze 16 – <a href=\"https://www.google.com/maps/place/BoTesla.cz/@49.7745406,18.2661135,17z/data=!4m7!3m6!1s0x4713fb5da96438c1:0x5ec9be7d90814aae!8m2!3d49.7745406!4d18.2661135!9m1!1b1\" target=\"_blank\">zobrazit více</a></span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Andrea Bínová"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"<span style=\"color: var(--color-blend--15);\">Dárek pro manžela, skvělý zážitek s paradním instruktorem. Moc děkujeme!</span>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp;"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Lenka Hájková"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Zakoupena spolujízda, super zážitek. Pan Jindra byla velmi ochotný a vstřícný i přesto, že jsme ten den byli už 7 zákazníci...<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp;"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--20 pt--20">
            
            <ColumnWrap className="column__flex --center el--3" columns={"3"}>
              
              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Pavel Novák"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Super zážitek, vše proběhlo skvěle, doporučuji na 100% a všem 👍👍👍<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp;"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Jan Menšík"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Pěkné auto, příjemný pán a skvělý zážitek.&nbsp;<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp; – místní průvodce"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Adam Schreier"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Great car and even better service.  Highly recommending to try them out ;)<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp;– místní průvodce"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--20 pt--20">
            
            <ColumnWrap className="column__flex --center el--3" columns={"3"}>
              
              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Roman Jank"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Zapůjčení auta na jeden den.\nPán veliký sympaťák, ochotný, všechno vysvětlil. Auto krásné, nachystané, nepředstavitelné....<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp;"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Josef Vynuchal"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Perfektní služby\n<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐&nbsp; – místní průvodce"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--shadow2 --left pb--16 pl--16 pr--16 pt--16" style={{"backgroundColor":"rgba(248,249,250,1)"}}>
                
                <Title className="title-box fs--16 w--600" content={"Dorian Manto"}>
                </Title>

                <Text className="text-box fs--16" style={{"maxWidth":319}} content={"Po setkání a usednutí do vozu mi pan instruktor vše velmi pěkně a vstřícně vysvětlil a bez dalších okolků jsme...<br>"}>
                </Text>

                <Text className="text-box fs--16 mt--06" style={{"maxWidth":650}} content={"\n⭐ ⭐ ⭐ ⭐ ⭐"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column name={"covasceka"} style={{"marginTop":0,"paddingTop":53,"paddingBottom":0,"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--30 pr--20 pt--50 flex--bottom" style={{"backgroundColor":"var(--black)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
              
              <Title className="title-box" style={{"maxWidth":540,"marginBottom":49}} content={"<span style=\"color: var(--color-custom-1--95);\">Co Vás čeká?</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":599,"marginTop":0,"marginBottom":35}} content={"Podle zvolené délky absolvujeme trasu z brány Beskyd (Frýdlantu) vzhůru dál do hor, zažijete parádní svezení v podobě předem pečlivě vybrané trasy plné krásných zatáček, rovinek pro vyzkoušení akcelerace a s menším provozem aby nás při jízdě nikdo nerušil. Po celou dobu s Vámi pojede instruktor, kovaný Teslař a vše Vám ochotně vysvětlí a zasvětí do tajů naší Tesly Y-lonky. Věříme, že pro Vás jízda bude skutečným zážitkem.\n"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--18" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":0}} content={"Chci koupit poukaz&nbsp;➜"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/27050/7c68101ba23b4860a8b344cea075d255_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":860}} srcSet={"https://cdn.swbpg.com/t/27050/7c68101ba23b4860a8b344cea075d255_s=350x_.png 350w, https://cdn.swbpg.com/t/27050/7c68101ba23b4860a8b344cea075d255_s=660x_.png 660w, https://cdn.swbpg.com/t/27050/7c68101ba23b4860a8b344cea075d255_s=860x_.png 860w, https://cdn.swbpg.com/t/27050/7c68101ba23b4860a8b344cea075d255_s=1400x_.png 1400w"}>
              </Image>

              <Text className="text-box" style={{"marginTop":0}} content={"úchvatný výkon&nbsp;<span style=\"color: var(--color-custom-1);\">➜ </span>kosmické zrychlení&nbsp;<span style=\"color: var(--color-custom-1);\">➜</span> nejmodernější technologie"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":"-4.5em"}} columns={"2"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --left" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/o/27050/57c46a0fe7344e1894f6be2815d58afc.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"vyzkousejylonku"} style={{"paddingTop":0,"paddingBottom":36}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--1" anim={"2"} style={{"maxWidth":882}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" style={{"marginBottom":8}} content={"Vyzkoušej Y-lonku"}>
              </Title>

              <Text className="text-box" style={{"marginTop":0,"marginBottom":36}} content={"od ledna 2023 – Poukazy již v prodeji"}>
              </Text>

              <YouTube embedId={"ebmiVjHZvbE"}>
              </YouTube>

              <Text className="text-box" style={{"maxWidth":650,"paddingTop":17,"marginBottom":22}} content={"<span style=\"font-weight: bold;\">Tak takhle zrychlovala naše krásná Tesla 3 Performace:-) Zažijte něco podobného v novém modelu Y!</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 btn-box--center fs--18" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":0}} content={"Koupit poukaz"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"galerie"} style={{"paddingTop":57,"paddingBottom":39}} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" style={{"marginBottom":16}} content={"Naše nová&nbsp;<span style=\"font-weight: bold;\">Y - lonka\n</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 pl--0 pt--0" style={{"maxWidth":1140}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/60a3ba95331a4e7f84b33a7e822aa23a_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/60a3ba95331a4e7f84b33a7e822aa23a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/60a3ba95331a4e7f84b33a7e822aa23a_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/cc8d389626e74c6b86b1ac5617d5d0dc_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/cc8d389626e74c6b86b1ac5617d5d0dc_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/cc8d389626e74c6b86b1ac5617d5d0dc_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/cc8d389626e74c6b86b1ac5617d5d0dc_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/5ac53fe27b1045388f3f05ca94fe8099_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/5ac53fe27b1045388f3f05ca94fe8099_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/5ac53fe27b1045388f3f05ca94fe8099_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/5ac53fe27b1045388f3f05ca94fe8099_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/a7b699ac0b89483fa73f8c35dede2e0f_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/a7b699ac0b89483fa73f8c35dede2e0f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/a7b699ac0b89483fa73f8c35dede2e0f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/a7b699ac0b89483fa73f8c35dede2e0f_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/3974648b0e1746e9831ec04295fd0931_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/3974648b0e1746e9831ec04295fd0931_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/3974648b0e1746e9831ec04295fd0931_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/3974648b0e1746e9831ec04295fd0931_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Tesla"} src={"https://cdn.swbpg.com/t/27050/67a13c87dc974f20880b6f9d2b2341c1_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/27050/67a13c87dc974f20880b6f9d2b2341c1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/67a13c87dc974f20880b6f9d2b2341c1_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/67a13c87dc974f20880b6f9d2b2341c1_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"voucher"} style={{"paddingTop":44,"paddingBottom":27}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"&nbsp;Zakoupit <span style=\"font-weight: bold;\">voucher ON-LINE</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":768}} content={"Uplatnění je možné od 15.1.2024 do 10.12.2024, uplatnění v 6.,7. a 8. měsíci pouze po dohodě (není garantováno) ve Frýdlantu nad Ostravicí, 739 11"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Voucher name={"ukkx1xmgrt8"}>
        </Voucher>


        <Column className="pb--80 pl--16 pr--16" name={"faq"} style={{"paddingTop":46,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1140,"marginBottom":84}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center w--600" style={{"marginTop":4}} content={"Často kladené otázky"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Odkud se jede a kde?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Jedeme z Frýdlantu n.O. (15minut z Ostravy směr FM), pojedeme předem vybranou trasou našimi instruktory. V případě zájmu však klidně pojedeme trasu jakou si sami vyberete a určíte.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Kdo řídí?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Podle zvoleného zážitku...buď Vy, nebo náš instruktor.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Může jet někdo s námi?\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Ano, 2 osoby a to zcela ZDARMA :-)&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Jak je to s pojištěním?\n\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Tesla je kompletně pojištěna v All risku, krom spoluúčasti se tedy nemusíte bát rizika velké finanční ztráty pokud by něco \"nevyšlo\". Znáte to, náhoda je blbec. Spoluúčast u vozu je 20000Kč/10%. U čelního skla bez spoluúčasti.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Jsou ceny konečné?\n\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Ano, v rámci zážitku je to pro Vás konečná cena, v ceně máte kompletní službu včetně potřebné energie :-)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Jak poukaz uplatnit?\n\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Na mail Vám po koupi dorazí veškeré informace. Pokud jste však obdarovaný a nemáte detaily nejpozději 3 týdny před koncem poukazu se ozvěte na náš mail (v nejnutnějších případech telefonicky) a navrhněte v jakém termínu a čase je pro Vás nejlepší poukaz využít. Nezapomeňte na sebe nechat telefon :-) Přidejte také kód poukazu.\n</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--2 pb--08 pt--08 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1140,"minHeight":"40px","gridRowGap":"0.5em","borderBottom":"2px solid #EFEFEF"}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"Do kdy a jak mohu zrušit rezervaci jízdy?\n\n\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--15);\">Zrušení či přesunutí termínu je nutné hlásit nejpozději 48 hodin předem (emailem), jinak se zákazník vystavuje riziku propadnutí termínu. Vyhrazujeme si právo na zrušení/přesunutí termínu v případě mimořádně nepříznivých klimatických podmínek nebo jiných neočekávaných situacích.\n</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--30" name={"paticka"} style={{"paddingBottom":18,"backgroundColor":"rgba(32, 32, 32, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kontakt\n</span>"}>
              </Title>

              <Text className="text-box fs--16" content={"<fontsninja-text id=\"fontsninja-text-258\" class=\"fontsninja-family-2033\">+420 555 222 451<br></fontsninja-text>BESKYD servis spol. s r.o.<br>Revoluční 149&nbsp;<br>739 11 Frýdlant n.O.<br>IČ 27800814<br>DIČ CZ27800814<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--16" style={{"marginTop":0}} content={"www.botesla.cz<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"boteslacz@gmail.com<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":7}} content={"<span style=\"color: var(--color-blend--15);\">GDPR | <a href=\"https://cdn.swbpg.com/d/27050/vseobecne-obchodni-podminky.pdf\">Obchodní podmínky</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}